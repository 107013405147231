.crisishulp {
  background-color: #ffffff;
}

.crisishulp,
.crisishulp a {
  color: var(--blue);
}

.crisishulp .crisishulp-sidebar {
  width: 340px;
  background-color: var(--light-blue);
}

.crisishulp .right-side {
  width: 50%;
  padding: 30px 20px;
}

.crisishulp .right-side .title {
  margin-top: 0;
}

.crisishulp .right-side .counseling-list-wrapper {
  display: flex;
  position: relative;
}

.crisishulp .right-side .counseling-list-wrapper .icon-and-text {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: -344px;
  max-width: 305px;
}

.crisishulp .right-side .counseling-list-wrapper .text {
  font-size: 18px;
}

.crisishulp .right-side .counseling-list-wrapper .icon-and-text .icon {
  width: 70px;
  height: 70px;
  margin-right: 15px;
}

.crisishulp .right-side .counseling-logos {
  gap: 20px;
  margin-left: 24px;
  margin-top: 25px;
  flex-wrap: wrap;
}

.crisishulp .right-side .counseling-logos img {
  height: 64px;
}

@media screen and (max-width: 850px) {
  /* Prevent urls from becoming too wide */
  .crisishulp a {
    word-break: break-all;
  }

  .crisishulp .crisishulp-sidebar {
    display: none;
  }

  .crisishulp .right-side {
    width: 100%;
  }

  .crisishulp .right-side .counseling-logos {
    margin: 20px auto;
    justify-content: space-evenly;
  }

  .crisishulp .right-side .counseling-logos img {
    height: 45px;
  }
}
