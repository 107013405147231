.start-scan {
  position: fixed;
  right: 25px;
  bottom: 215px;
  background-color: #ffbd4a;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 28px -12px rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.start-scan img {
  filter: invert(1);
  width: 30px;
  height: 30px;
}

.start-scan:hover {
  cursor: pointer;
}

@media (max-width: 479px) {
  .start-scan {
    bottom: 145px;
    width: 45px;
    height: 45px;
    right: 15px;
  }

  .start-scan img {
    width: 25px;
    height: 25px;
  }
}
