.user-sidebar {
  min-height: calc(100vh - 145px);
}

.user-sidebar .sidebar {
  border-right: 1px solid #e7e9ec;
  border-top: 1px solid #e7e9ec;
  background-color: var(--light-grey2);
  width: 280px;
  padding: 25px;
  margin: 0;
}

.user-sidebar .sidebar .sidebar-item {
  padding: 15px 20px;
  color: var(--dark-grey);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  font-weight: 600;
}

.user-sidebar .sidebar .sidebar-item.active,
.user-sidebar .sidebar .sidebar-item:hover {
  background: var(--pink2);
  color: white;
  border-radius: 3px;
}

.user-sidebar-mobile .hamburger-menu .sidebar-item {
  padding: 16px 10px;
  color: var(--dark-grey);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  justify-content: center;
}

.user-sidebar.sidebar-container {
  height: calc(100% - 76px);
}

.user-sidebar .sidebar-container .content {
  width: calc(100% - 201px);
}

.content.user-content {
  width: calc(100% - 340px);
  min-height: calc(100vh - 145px - 60px);
}

.user-sidebar-mobile .hamburger-menu .sidebar-item .icon,
.user-sidebar .sidebar .sidebar-item .icon {
  margin-right: 12px;
  width: 33px;
  height: 33px;
}

.user-sidebar-mobile .hamburger-menu .sidebar-item .icon.active,
.user-sidebar .sidebar .sidebar-item .icon.active {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2000%)
    hue-rotate(0deg);
}

.user-sidebar-hamburger {
  filter: brightness(0) saturate(100%) invert(22%) sepia(21%) saturate(1084%)
    hue-rotate(153deg) brightness(95%) contrast(92%);
  width: 40px;
}

.user-sidebar-cross {
  color: var(--blue);
  padding-top: 30px;
  padding-left: 30px;
}

.hamburger-menu {
  position: absolute;
  left: 0;
  height: calc(100vh - 190px);
  width: 100%;
  flex-direction: column;
  top: 0;
  margin-top: 190px;
  z-index: 1;
  background-color: #fffbfa;
}

.user-content {
  background-color: white;
  padding: 30px;
  height: 100%;
}

@media (max-width: 1100px) {
  .user-content.content {
    width: calc(100vw - 60px);
  }

  .user-information-container {
    margin: 25px 0px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 10px;
    height: auto;
  }

  .user-information-container .user-info-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .user-information-container .user-info-items .user-info-item {
    width: 90%;
  }
}
