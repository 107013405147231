.impact-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.impact-slider .impact-results {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.impact-slider .impact-results .result {
  display: flex;
  width: 25%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.impact-slider .impact-results .result .number {
  font-size: 20px;
  font-weight: bold;
  color: #6fa0af;
}

.impact-slider .slider-wrapper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.impact-slider .slider-wrapper .slider-title {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .impact-slider .impact-results .result {
    width: 100%;
    margin-bottom: 20px;
  }
}
