.game-wrapper {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.game-started {
  display: flex;
}

.game-started .left {
  background: #6b9aad;
  padding-left: 10px;
}

.game-started .left,
.game-started .right {
  width: 400px;
}

.game-started .middle {
  width: 100%;
  margin: 20px;
}

.game-started .white-box {
  background: white;
  padding: 20px;
}

.comment-box {
  margin-top: 10px;
  margin-left: 20px;
  background: #fafbfd;
  padding: 10px;
}

.accept-button {
  color: white;
  background: #74a0ad;
  border-radius: 100%;
  padding: 20px;
  font-size: 30px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.deny-button {
  color: white;
  background: #ffa176;
  border-radius: 100%;
  padding: 20px;
  font-size: 30px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
