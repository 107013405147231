.favorites hr {
  background-color: #e6eaf0;
  border: 0;
  box-sizing: content-box;
  height: 2px;
}

.favorites .favorite-item {
  margin-top: 15px;
  margin-bottom: 15px;
}

.favorites .favorite-item a {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: #122b46;
  text-decoration: none;
}

.favorites .all-item {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  flex-basis: calc(45% - 50px);
  flex-shrink: 1;
  margin: 40px;
  height: fit-content;
}

.favorites .all-item .all-title {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  text-align: center;
  color: #96b8c2;
  margin-bottom: 25px;
}
