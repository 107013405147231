.table {
  height: 600px;
  font-family: "Montserrat", sans-serif;
  --ag-borders: none !important;
}

.table .ag-header,
.table .ag-row {
  background-color: transparent;
  border-bottom: solid;
  border-width: 2px;
  border-color: #d6e5ea;
}

.table .ag-cell,
.table .ag-header-cell {
  font-size: 18px;
  color: var(--blue);
}

.table .text-cell {
  padding-top: 7px;
}

.table .button-cell {
  display: flex;
  align-items: center;
}

.table .button-cell .table-button {
  cursor: pointer;
  margin-left: auto;
}

.table .button-cell svg {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 500px) {
  .table .ag-cell,
  .table .ag-header-cell {
    font-size: 13px;
  }

  .table .button-cell svg {
    width: 30px;
    height: 30px;
  }
}
