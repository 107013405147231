.small-stats-box {
  display: flex;
  height: 80px;
  width: 140px;
  border: 1px solid;
  border-radius: 10px;
  padding: 8px;
}

.small-stats-box .text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.small-stats-box .number {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.small-stats-box .icon {
  width: 40px;
  height: 40px;
}

.premium {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -105px;
  margin-left: -5px;
}

.small-stats-box.users {
  background-color: var(--light-orange);
  border-color: var(--orange);
}

.small-stats-box.modules {
  background-color: var(--light-grey-blue);
  border-color: var(--grey-blue);
}

.small-stats-box.testen {
  background-color: var(--light-green);
  border-color: var(--green);
}

.small-stats-box.face-scans {
  background-color: var(--light-purple);
  border-color: var(--purlple);
}

.small-stats-box.tevreden {
  background-color: var(--light-turquoise);
  border-color: var(--turquoise);
}

.small-stats-box.skills {
  background-color: var(--light-yellow);
  border-color: var(--yellow);
}

.small-stats-box.podcasts {
  background-color: rgba(248, 248, 255);
  border-color: var(--dark-purple);
}

.small-stats-box.locked {
  opacity: 0.35;
}

@media screen and (max-width: 800px) {
  .small-stats-box {
    height: 75px;
    width: 130px;
    padding: 6px;
  }
}
