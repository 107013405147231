.new-header {
  background-color: var(--white);
}

.new-header .header-wrapper {
  padding: 15px 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-header .logo {
  height: 56px;
}

.new-header .header-top .crisis .crisis-text {
  color: var(--dark-grey);
}

.new-header .header-top .crisis .exclamation-mark {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.new-header .lang-select {
  position: absolute;
  right: 51px;
  top: 59px;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 20px;
  color: white;
  z-index: 10;
}

.new-header .second {
  background-color: var(--blue);
  padding: 15px 75px;
  color: var(--white);
  justify-content: center;
  display: flex;
  gap: 40px;
  overflow: auto;
}

.new-header .second.open {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 1000px;
}

.new-header .second {
  overflow: hidden;
}

.new-header .second .second-inside {
  display: flex;
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.new-header .menu-item {
  color: var(--white);
  font-size: 18px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.new-header .menu-item:hover,
.new-header .menu-item.active {
  border-bottom: 2px solid var(--white);
}

.new-header .menu-item.disabled {
  color: #bbbbbb;
  cursor: url("./img/Premium.svg"), not-allowed;
}

.new-header .menu-item:hover.disabled {
  border-bottom: 2px solid transparent;
}

.menu-icon-container {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 850px) {
  .new-header .header-wrapper {
    padding: 15px 15px;
    flex-wrap: wrap;
    gap: 15px;
  }

  .new-header .second.closed {
    display: none;
  }

  .new-header .second {
    padding: 15px 15px;
    width: calc(100% - 30px);
    overflow: hidden;
    justify-content: start;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .new-header .second.open {
    max-height: 500px;
  }

  .menu-icon-container {
    display: flex;
    align-items: flex-end;
    background-color: var(--blue);
  }

  .menu-icon {
    color: white;
    display: block;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    margin-left: auto;
  }

  .new-header .second .second-inside {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .new-header .lang-select {
    right: 5px;
    top: 112px;
  }
}

.bloom-mondays-banner {
  background-color: #56b1eb;
  color: rgba(0, 0, 0, 0.87);
  padding: 16px 0;
  text-align: center;
  font-weight: bold;
}

.bloom-mondays-banner__link {
  text-decoration: none;
}

@media screen and (max-width: 850px) {
  .new-header .header-top .crisis .crisis-text {
    font-size: 13px;
  }
}
