h1 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 34px;
  color: #122b46;
}

h2 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: #122b46;
}

h3 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #122b46;
}

.home .section-left {
  display: flex;
  flex-direction: column;
}

.home .section-left h1 {
  max-width: 480px;
  margin-bottom: auto;
}

.home .banner {
  padding: 36px 10%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.home .banner-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.home .banner-title.w30 {
  width: 30%;
}

.home .banner img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.about .why-mobile {
  display: none;
}

.about {
  padding: 0 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 130px;
  flex-wrap: wrap;
}

.about .why {
  width: 45%;
  display: flex;
  justify-content: center;
}

.about .how {
  width: 45%;
}

.dash {
  border-bottom: 2px dashed white;
  width: 200px;
  transform: translateY(-56%);
}

.step.last {
  margin-left: -74px;
}

.home .quote {
  background-color: var(--light-blue);
  padding-bottom: 50px;
}

.home .quote-content {
  justify-content: center;
}

.home .hero-section h1 {
  color: var(--yellow);
  font-weight: bolder;
  font-size: 45px;
  margin-bottom: 40px;
  line-height: 65px;
}

.home .hero-section .section-right {
  display: flex;
  justify-content: center;
}

.home .hero-section .section-right img {
  max-width: 460px;
  max-height: 460px;
  width: "100%";
  object-fit: contain;
}

.home .content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
}

.home .hero-section {
  margin-bottom: 40px;
  max-width: 1000px;
  margin-top: 50px;
  min-height: 460px;
}

.progress.to-export {
  width: fit-content;
}

.progress.to-export .progress-block {
  padding: 40px;
}

.gif-container {
  position: absolute;
  width: 600px;
  height: auto;
  left: calc(50% - 300px);
}

@media screen and (max-width: 999px) {
  .progress .progress-block {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1150px) {
  .home .hero-section {
    display: flex;
  }

  .progress .progress-content {
    display: flex;
  }

  .progress .progress-block {
    margin-right: 15px;
  }

  .home .quote-content {
    display: flex;
  }
}

.progress .progress-block {
  background-color: var(--light-blue);
  border-radius: 15px;
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.progress .progress-block:last-child {
  margin-right: 0;
}

.progress .progress-block .time-circle {
  border: 4px solid var(--yellow);
  background-color: white;
  border-radius: 160px;
  height: 190px;
  width: 190px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}

.progress .progress-block .time-circle .hours {
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 10px;
}

.progress .progress-block .time-circle .minutes {
  font-size: 16px;
  font-weight: 800;
}

.progress .progress-block .title {
  font-size: 22px;
  color: var(--blue);
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 15px;
}

.progress .progress-block .progress-block-content {
  flex: 1;
}

.progress .progress-block .more {
  font-size: 15px;
  color: var(--blue);
  font-weight: bold;
  align-items: center;
  margin-top: auto;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
}

.progress .progress-block .module-block {
  background-color: var(--white);
  padding: 10px;
  flex: 1;
  align-items: center;
  height: 62px;
}

.progress .progress-block .module-wrapper {
  margin-bottom: 10px;
  text-decoration: none;
}

.progress .progress-block .coloured-border {
  width: 10px;
}

.progress .progress-block .module-block .module-img {
  flex: 0.2;
  display: flex;
  margin-right: 8px;
}

.progress .progress-block .module-block .module-img .img {
  height: 50px;
  width: 50px;
}

.progress .progress-block .module-block .module-content {
  flex: 1;
}

.progress .progress-block .module-block .module-title {
  color: var(--blue);
}

.progress .progress-block .module-bar-wrapper {
  background-color: #e7e9ec;
  border-radius: 10px;
}

.progress .arrow-blue {
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  border-top: 4px solid var(--blue) !important;
  border-left: 4px solid var(--blue) !important;
  margin: 0 15px;
}

.progress .inspiration-block .arrow-blue {
  margin: 0;
  position: absolute;
  right: 5%;
}

.progress .arrow-blue {
  transform: rotate(135deg);
}

.progress .progress-block .module-bar-wrapper {
  background-color: #e7e9ec;
  border-radius: 10px;
}

.progress .progress-block .module-bar {
  height: 15px;
  background-color: var(--yellow);
  border-radius: 10px;
}

.progress .progress-block .inspiration-block {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  height: 62px;
  position: relative;
}

.progress .progress-block .inspiration-block a {
  text-decoration: none;
}

.progress .progress-block .inspiration-block .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95px;
  flex: 0.45;
}

.progress .progress-block .inspiration-block:last-child {
  margin-bottom: 0;
}

.progress .progress-block .inspiration-block .icon img {
  height: 50px;
  width: auto;
}

.progress .progress-block .inspiration-block .inspiration-title {
  position: absolute;
  left: 32%;
  color: var(--blue);
  flex: 1;
}

.home .quote .quote-text {
  text-align: center;
}

.home .quote .author {
  margin-top: 20px;
  justify-content: center;
}

.home .quote .quote-left {
  margin-top: auto;
  margin-bottom: auto;
  flex: 1;
}

.home .quote .quote-left {
  color: black;
  padding: 0 25px;
}

.home .quote .logo-right {
  flex: 0.4;
  display: flex;
  justify-content: center;
  padding-bottom: 120px;
}

.home .quote .author img {
  width: 100px;
  height: auto;
}

.home .quote .quote-left .quote-author-details {
  margin-left: 25px;
  align-self: center;
}

.home .quote .quote-left .quote-author {
  font-weight: bold;
}

.home .waves-block {
  width: 100%;
  padding: 0;
}

@media screen and (max-width: 450px) {
  .home .waves-block {
    margin-top: 0;
    margin-bottom: -10px;
  }
}

@media screen and (max-width: 700px) {
  .home .section-left {
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 451px) {
  .home .waves-block {
    margin-top: 0;
    margin-bottom: -33px;
  }
}

@media screen and (min-width: 1151px) {
  .home .waves-block {
    margin-top: -10px;
    margin-bottom: -33px;
  }
}

@media screen and (min-width: 1601px) {
  .home .waves-block {
    margin-bottom: -33px;
  }
}

@media screen and (min-width: 2500px) {
  .home .waves-block {
    margin-bottom: -33px;
  }
}

@media screen and (max-width: 1083px) {
  .about {
    margin-bottom: 25px;
  }

  .about .why-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 25px;
  }

  .about .why {
    display: none;
  }

  .about .how {
    width: 100%;
  }

  .dash {
    display: none;
  }

  .step {
    width: 100%;
    margin: 10px 0;
  }

  .step.last {
    margin-left: 0;
  }
}

@media screen and (max-width: 500px) {
  .banner-title {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .banner img {
    padding: 10px;
    width: 100%;
  }
}

.mySlides {
  display: flex;
}

/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #febd4d;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  font-size: 48px;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.arrow-right,
.arrow-left {
  display: inline-block;
  margin: 40px auto;
  width: 25px;
  height: 25px;
  border-top: 8px solid #4d4d4d;
  border-left: 8px solid #4d4d4d;
  cursor: pointer;
}

.arrow-right {
  transform: rotate(135deg);
}

.arrow-left {
  transform: rotate(-45deg);
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.connect-business {
  background-color: #649ea8;
  color: white;
}

.connect-business .connect-business-box {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .slide-text {
    font-size: 11px;
  }
}
