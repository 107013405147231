.modules {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.modules h2 {
  font-size: 28px;
  color: #122b46;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 2rem 0;
}

.module {
  max-width: 300px;
  border: 1px solid #e7e9ec;
  border-radius: 3px 3px 0 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  background: white;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 500px) {
  .module {
    max-width: unset;
  }
}

.module:hover {
  box-shadow: 0 4px 32px 0 rgba(18, 43, 70, 0.1);
}

.module .ribbon {
  position: absolute;
  margin-top: 20px;
  text-transform: uppercase;
  border-radius: 0 15px 15px 0;
  color: #fff;
  text-align: center;
  padding: 5px 12px;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.23px;
}

.module .ribbon.finished {
  background-color: #1cd991;
}

.module .ribbon.started {
  background-color: #007cff;
}

.module .ribbon.to-start {
  background-color: #dbbe1a;
}

.module .module-image {
  border-radius: 3px 3px 0 0;
  background-color: #607387;
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.module .content {
  padding: 16px 20px;
  background-color: white;
}

.module .content .lessons,
.module .content .description {
  color: #a3a5a9;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 25px;
  font-weight: 300;
}

.module .content .pricing {
  font-weight: 500;
  font-size: 20px;
  color: #122b46;
}

.module .content .title {
  color: #122b46;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.24;
  margin: 0 0 8px;
}

@media screen and (max-width: 500px) {
  .module {
    width: 100%;
  }
}
