.hr {
  border-bottom: 1px solid #e7e9ec;
  margin-top: 10px;
  margin-bottom: 15px;
}

.profile-name {
  background-color: white;
  padding: 45px;
  font-size: 2rem;
  color: #122b46;
  margin-top: 25px;
  border: 1px solid #e7e9ec;
}

.profile-settings {
  border: 1px solid #e7e9ec;
  background-color: white;
  margin-top: 20px;
}

.profile-settings .profile-sidebar {
  background-color: #fbfbfc;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 200px;
  margin-right: 20px;
  border-right: 1px solid #e7e9ec;
}

.profile-settings .profile-sidebar .profile-sidebar-item.active {
  background-color: #dadce0;
  color: #152c48;
}

.profile-settings .profile-sidebar .profile-sidebar-item:hover {
  background-color: #eaebed;
  color: #152c48;
}

.profile-settings .profile-sidebar .profile-sidebar-item {
  padding: 15px 20px;
  color: #939597;
  cursor: pointer;
}

.profile-settings .profile-content {
  padding-top: 20px;
  padding-bottom: 20px;
  width: calc(100% - 240px);
}

.profile-settings .profile-content .profile-content-title {
  color: #152c48;
  font-size: 18px;
}

.profile-settings .profile-content table {
  width: 100%;
}

.profile-settings .profile-content table .label {
  width: 25%;
  padding-bottom: 10px;
  color: #a3a5a9;
}

.qr {
  width: 215px;
}
