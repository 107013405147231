.business-dashboard .stats-box {
  background-color: white;
  padding: 40px;
  width: 220px;
  display: flex;
  flex-direction: column;
}

.business-dashboard .stats-box.full {
  width: initial;
}

.business-dashboard .stats-box.half {
  width: 45%;
}

.business-dashboard .stats-box.max-height {
  max-height: 85px;
}

.business-dashboard .stats-box .number {
  color: #fcba55;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.business-dashboard .stats-box .title {
  font-size: 15px;
  text-align: center;
  color: #2d4f60;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
}

.business-dashboard .stats-box.reverse .title {
  margin-top: 0;
  margin-bottom: 20px;
}

.business-dashboard .download {
  text-decoration: none;
  color: white;
}

.business-dashboard h2 {
  font-weight: 700;
  font-size: 23px;
}

.business-dashboard a {
  text-decoration: none;
}

.business-dashboard .graph {
  position: relative;
  background: var(--white);
  border: 1px solid var(--grey);
  border-radius: 10px;
  padding: 5px 5px 20px 5px;
  width: calc((100vw - 280px - 50px - 60px - 45px - 30px - 10px) / 3);
  max-height: 450px;
}

.business-dashboard .locked {
  border-color: var(--light-grey);
}

.locked-content {
  opacity: 0.35;
}

.graph-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  margin-left: 20px;
  margin-top: 20px;
}

.business-dashboard .graph-wrapper {
  overflow: unset;
  padding: 0 15px 0;
  max-height: calc(450px - 30px);
}

.business-dashboard .graph-wrapper.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.business-dashboard .graph-wrapper.overflow-auto {
  overflow: auto;
}

.graph-wrapper.click:hover {
  cursor: pointer;
}

.tooltip-icon {
  z-index: 20;
  margin-left: 5px;
}

.tooltip {
  max-width: 200px;
  z-index: 100;
  font-size: 14px !important;
}

.graph-premium {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1555px) {
  .business-dashboard .graph {
    width: calc((100vw - 280px - 50px - 60px - 30px - 20px - 10px) / 2);
  }
}

/* all stylesheets for rendering in pdf */

.stats-box-row.pdf {
  justify-content: center;
}

.stats-box-row.pdf .small-stats-box {
  display: flex;
  height: 100px;
  width: 165px;
  margin-top: 10px;
}

.stats-box-row.pdf .number {
  font-size: 25px;
}

.stats-box-row.pdf .text {
  font-size: 15px;
}

.stats-box-row.pdf .icon {
  width: 65px;
  height: 65px;
}

.stats-box-row.pdf .premium {
  margin-top: -130px;
  width: 25px;
  height: 25px;
}

.stats-box-row.pdf {
  gap: 30px;
}

@media (max-width: 768px) {
  .business-dashboard .graph {
    width: 100vw;
    overflow: hidden;
  }
}
