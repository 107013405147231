.certificates-page .certificates-wrapper {
  max-height: calc(100vh - 145px - 60px - 100px);
  overflow: auto;
}

.certificates-page h2 {
  font-weight: 700;
  font-size: 23px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-color: var(--yellow);
  border-bottom-width: 2px;
}

.certificates-page .certificate-item {
  background-color: var(--light-blue);
  width: 60%;
  max-width: 650px;
  margin-bottom: 30px;
  align-content: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.certificates-page .certificate-item .certificate-content {
  padding: 5px 20px;
  align-items: center;
  width: 100%;
}

.certificates-page .certificate-item .certificate-title {
  font-weight: 600;
  font-size: 19px;
  margin-left: 50px;
}

.certificates-page .certificate-item .certificate-download {
  font-size: 30px;
}

.certificates-page .certificate-item .coloured-border {
  width: 10px;
}

.certificates-page .certificate-item .certificate-content .module-img {
  flex: 0 1;
  display: flex;
  margin-right: 8px;
}

.certificates-page .certificate-item .certificate-content .module-img .img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.certificates-page
  .certificate-item
  .certificate-content
  .download-icon-wrapper {
  margin-left: auto;
  align-items: center;
}

.certificates-page
  .certificate-item
  .certificate-content
  .download-icon-wrapper
  .icon {
  color: var(--dark-grey);
  font-size: 40px;
}

@media (max-width: 1000px) {
  .certificates-page .certificate-item {
    width: 90%;
  }

  .certificates-page .certificate-item .certificate-title {
    font-size: 16px;
    margin-left: 15px;
  }

  .certificates-page
    .certificate-item
    .certificate-content
    .download-icon-wrapper
    .icon {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .certificates-page .certificate-item {
    width: 100%;
  }

  .certificates-page .certificate-item .certificate-title {
    margin-left: 10px;
  }

  .certificates-page .certificates-wrapper {
    max-height: none;
  }
}
