.scanner-overlay {
  z-index: 1000001; /* crisp Z-index + 1*/
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.scanner-body {
  position: absolute;
  max-width: 1600px;
  width: 89%;
  height: 89%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow:
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 20px 25px -5px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 15px;
  max-height: 95%;
}

.scanner-body:focus {
  border: none;
  outline: 0;
}

.scanner-open {
  overflow: hidden;
}

@media (max-width: 768px) {
  .scanner-body {
    height: 80%;
  }
}
