.mindlab-mail-icon {
  position: fixed;
  right: 25px;
  bottom: 95px;
  background-color: #ffbd4a;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
  z-index: 9998;
}

.business-mail-icon {
  position: fixed;
  right: 25px;
  bottom: 95px;
  background-color: var(--grey-blue);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
  z-index: 9998;
}

.business-mail-wrapper {
  position: fixed;
  right: 25px;
  bottom: 15px;
  background-color: var(--orange);
  border-radius: 15px;
  width: 190px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
  z-index: 1000001;
}

.mindlab-mail-wrapper {
  position: fixed;
  right: 25px;
  bottom: 85px;
  background-color: var(--orange);
  border-radius: 15px;
  width: 190px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.mindlab-mail-wrapper .person-icon,
.business-mail-wrapper .person-icon {
  font-size: 80px;
  color: var(--grey-blue);
  margin-top: -40px;
  margin-bottom: 5px;
}

.mindlab-mail,
.business-mail {
  text-decoration: none;
}

.mindlab-mail .button,
.business-mail .button {
  padding: 3px 8px;
  background-color: var(--light-grey2);
  border-color: var(--light-grey2);
  border-radius: 15px;
  color: var(--dark-grey);
  font-size: 15px;
  font-weight: 500;
}

.mindlab-mail-icon img,
.business-mail-icon img {
  filter: invert(1);
  width: 25px;
  height: 25px;
}
