.progress-container {
  height: calc(100% - 136px);
  display: flex;
}

.progress-container .progress-content {
  overflow: auto;
  width: 100%;
  margin: 60px 60px 25px 60px;
}

.progress-container .progress-content .actions {
  color: #649ea8;
  display: flex;
  margin-top: 20px;
  font-size: 24px;
  cursor: pointer;
}

.progress-container .progress-content .actions .active {
  color: #3c656b;
}

.progress-container .progress-content .actions a {
  text-decoration: none;
  color: #649ea8;
}

.progress-container .progress-content .actions .margin {
  margin-right: 6px;
}

.progress-content .breadcrumb {
  display: flex;
  font-size: 13px;
  border-bottom: 1px solid #e7e9ec;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.progress-content .breadcrumb .link {
  text-decoration: none;
  color: #122b46;
}

.module-sidebar-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fafbfd;
  max-width: 370px;
  width: 100%;
  box-shadow: inset -36px 0 40px -32px rgba(0, 0, 0, 0.05);
}

.module-sidebar-container .title {
  color: #122b46;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
  border-bottom: 1px solid #e7e9ec;
  padding-bottom: 25px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}

.module-sidebar-container .bar-wrapper {
  margin-bottom: 19px;
  width: 84%;
  background-color: #e7e9ec;
  border-radius: 10px;
  margin-left: 30px;
}

.module-sidebar-container .bar-wrapper {
  margin-bottom: 5px;
  background-color: #e7e9ec;
  border-radius: 10px;
}

.module .bar-wrapper {
  margin-bottom: 5px;
  width: 100%;
  background-color: #e7e9ec;
  border-radius: 10px;
}

.bar {
  width: 0%;
  height: 6px;
  background-color: #ffbd4a;
  text-align: center;
  line-height: 30px;
  border-radius: 10px;
  color: white;
}

.module-sidebar-container .lesson-wrapper {
  padding: 12px 30px 12px 30px;
  cursor: pointer;
}

.module-sidebar-container .lesson {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: auto;
  display: block;
}

.module-sidebar-container .lesson svg {
  margin-right: 8px;
}

.module-sidebar-container .lesson-count {
  color: #a3a5a9;
  font-size: 12px;
  white-space: nowrap;
}

.lessons .subject,
.module-sidebar-container .subject {
  font-size: 13px;
  color: #939597;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 40px;
  cursor: pointer;
  text-decoration: none;
}

.lessons .subject.no-pointer,
.module-sidebar-container .subject.no-pointer {
  cursor: unset;
}

.module-sidebar-container .subject.active,
.module-sidebar-container .subject:hover,
.module-sidebar-container .lesson-wrapper:hover,
.lessons .subject.active,
.lessons .subject:hover,
.lessons .lesson-wrapper:hover {
  background: rgba(146, 164, 183, 0.1);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.module-sidebar-container .subject img,
.lessons .subject img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.module-sidebar-container .subject.visited,
.lessons .subject.visited {
  color: lightgray;
}

.lessons .visited-check,
.module-sidebar-container .visited-check {
  background-color: #ffbd4a;
  color: white;
  border-radius: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.module-sidebar-container .overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 76%;
}

.progress-container h1,
.tests .test-result {
  padding-bottom: 20px;
  border-bottom: 3px solid #ffbd4a;
}

.tests {
  margin: 20px;
}

.bottom-navigator {
  border-top: 1px solid #e7e9ec;
  padding-top: 10px;
  display: flex;
}

@media screen and (max-width: 500px) {
  .progress-container .progress-content {
    margin: 60px 20px 25px 20px;
  }
}
