.range.slider-1 {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slick-next:before,
.slick-prev:before {
  color: #ffbd4a;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  margin-left: 30px;
  margin-right: 30px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.range.slider-1::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #6fa0af;
  cursor: pointer;
}

.range.slider-1::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #6fa0af;
  cursor: pointer;
}

.onboarding img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  max-width: 20%;
}
