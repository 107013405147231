.ssoLandingContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 660px) {
    top: 1em;
  }
  top: 40vh;
}
