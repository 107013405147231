.slider {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  justify-content: center;
  position: relative;
  margin: 40px auto 150px;
}

.slider.wider {
  max-width: 85%;
}

.slider.testimonial-wrapper {
  min-height: 600px;
  min-width: 970px;
  margin-bottom: 75px;
}

.slider .slider-wrapper {
  top: 20px;
  position: absolute;
  gap: 35px;
  max-width: 100%;
}

.slider.testimonial-wrapper .slider-wrapper {
  min-height: 600px;
}

.slider .image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider .image-container .program-name {
  text-align: center;
}

.slider.testimonial-wrapper .image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 5);
}

.slider .image-container .image,
.slider.testimonial-wrapper .image-container .image {
  width: 130px;
  height: 130px;
  object-fit: contain;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: auto;
}

.slider.testimonial-wrapper .link {
  color: #4d5c6d;
  text-decoration: none;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
}

.slider.testimonial-wrapper .testimonial {
  border-radius: 8px;
  background-color: var(--light-blue);
  width: calc((min(calc(100vw - 120px), calc(1200px)) / 3) - 60px);
  margin: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.slider.testimonial-wrapper .testimonial.smaller-width {
  width: calc(min(calc(100vw - 120px), calc(1200px)) / 3 - 130px);
}

.slider.testimonial-wrapper .testimonial.empty {
  background-color: transparent;
}

.slider.testimonial-wrapper .testimonial .subtitle {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}

.slider.testimonial-wrapper .testimonial .text {
  background-color: var(--white);
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  font-style: italic;
  margin-bottom: 15px;
  text-align: center;
}

.slider.testimonial-wrapper .testimonial .stars {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.slider.testimonial-wrapper .testimonial .stars .star {
  width: 35px;
  height: 35px;
}

.slider.testimonial-wrapper .testimonial .name {
  margin-top: auto;
  font-style: italic;
  text-align: center;
}

.slider.testimonial-wrapper .arrow {
  z-index: 1;
}

.slider .arrow.push-down {
  margin-top: 35px;
  z-index: 35;
}

.slider.testimonial-wrapper .arrow.left {
  margin-right: auto;
}

.slider.testimonial-wrapper .arrow.right {
  margin-left: auto;
}

.testimonial-wrapper .minimal-width {
  min-width: 970px;
}

@media screen and (max-width: 1100px) {
  .testimonial-wrapper .minimal-width {
    min-width: 0;
  }

  .slider.testimonial-wrapper {
    min-height: 0;
    min-width: 0;
  }

  .slider.testimonial-wrapper .testimonial,
  .slider.testimonial-wrapper .testimonial.smaller-width {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 750px) {
  .slider.testimonial-wrapper .image-container {
    width: calc(100% / 2);
  }

  .slider.testimonial-wrapper .testimonial,
  .slider.testimonial-wrapper .testimonial.smaller-width {
    width: calc((min(calc(100vw - 40px), calc(1200px))) - 200px);
    margin-top: 10px;
  }
}
