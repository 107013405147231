.dialog-overlay {
  z-index: 20;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.dialog-body {
  background-color: white;
  position: absolute;
  width: 100%;
  max-height: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  outline: none;
  border-radius: 5px;
  box-shadow:
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.dialog-body.is-small {
  max-width: 500px;
}

.dialog-body.is-xsmall {
  max-width: 400px;
}

.dialog-body.is-large {
  max-width: 676px;
}

.dialog-body.is-wide {
  max-width: 900px;
}

.dialog-body.p-8 {
  padding: 2rem 2rem 2rem 2rem;
}

.dialog-body.is-xlarge {
  width: 1300px;
  height: 1300px;
}

.dialog-header {
  display: flex;
  z-index: 1;
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.dialog-title {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.dialog-open {
  /*overflow: hidden;*/
}

.dialog-footer {
  z-index: 1;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #2ec8b7;
}

.dialog-cancel {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #9fb3c8;
  transition: all 100ms;
}

.dialog-cancel:hover {
  color: #2ec8b7;
}

.dialog-body .close-button {
  color: #9fb3c8;
  transition: all 100ms;
  cursor: pointer;
}

.dialog-body .close-button:hover {
  color: #2ec8b7;
}

@media screen and (max-width: 500px) {
  .dialog-body {
    max-width: 80% !important;
  }
}
