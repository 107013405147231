.mood .range {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  width: 100%;
}

.mood input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: rgba(59, 173, 227, 1);
  background: -moz-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: -o-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: -ms-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FE0F17 ', endColorstr='#52E151 ', GradientType=1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3bade3 ', endColorstr='#ff357f ', GradientType=1);
  height: 6px;
}

.mood input[type="range"]:focus {
  outline: none;
}

.mood input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  background: #ffff43;
  background: -moz-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: -o-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: -ms-linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  background: linear-gradient(
    45deg,
    #fe0f17 0%,
    #ffc034 25%,
    #ffff43 50%,
    #a7ee4d 75%,
    #52e151 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FE0F17 ', endColorstr='#52E151 ', GradientType=1);
  height: 6px;
}

.mood input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  max-width: 80px;
  position: relative;
  bottom: 11px;
  background-color: #1d1c25;
  cursor: -webkit-grab;

  -webkit-transition: border 1000ms ease;
  transition: border 1000ms ease;
}

.mood input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  max-width: 80px;
  position: relative;
  bottom: 11px;
  background-color: #1d1c25;
  cursor: -moz-grab;
  -moz-transition: border 1000ms ease;
  transition: border 1000ms ease;
}

.mood .range.red::-webkit-slider-thumb {
  background-color: #fe0f17;
}

.mood .range.orange::-webkit-slider-thumb {
  background-color: #ffc034;
}

.mood .range.yellow::-webkit-slider-thumb {
  background-color: #ffff43;
}

.mood .range.ltgreen::-webkit-slider-thumb {
  background-color: #a7ee4d;
}

.mood .range.green::-webkit-slider-thumb {
  background-color: #52e151;
}

.mood .range.red::-moz-range-thumb {
  background-color: #fe0f17;
}

.mood .range.orange::-moz-range-thumb {
  background-color: #ffc034;
}

.mood .range.yellow::-moz-range-thumb {
  background-color: #ffff43;
}

.mood .range.ltgreen::-moz-range-thumb {
  background-color: #a7ee4d;
}

.mood .range.green::-moz-range-thumb {
  background-color: #52e151;
}

.mood input[type="range"]::-webkit-slider-thumb:active {
  cursor: -webkit-grabbing;
}

.mood input[type="range"]::-moz-range-thumb:active {
  cursor: -moz-grabbing;
}

@supports (-ms-ime-align: auto) {
  .mood .range {
    -webkit-appearance: slider-horizontal;
  }
}
