.module-detail .wrapper {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
}

.module-detail .banner {
  background-color: #607387;
  overflow: hidden;
  width: calc(100% + 7px);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding-bottom: 30px;
  color: white;
}

.module-detail .module-certificate {
  border: 1px solid #1cd991;
  border-radius: 6px;
  margin: 1em 0;
  padding: 15px;
  min-height: inherit;
  color: inherit;
  font-size: 15px;
  background-color: rgba(1, 158, 124, 0.1);
}

.module-detail .banner:before {
  content: "";
  opacity: 0.4;
  background-color: #122b46;
  background-image: -webkit-linear-gradient(270deg, transparent 4%, #000 100%);
  background-image: linear-gradient(-180deg, transparent 4%, #000 100%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.module-detail .wrapper .left {
  max-width: -webkit-calc(100% - 480px);
  max-width: calc(100% - 480px);
  width: -webkit-calc(100% - 480px);
  width: calc(100% - 480px);
}

.module-detail .banner .wrapper .content {
  color: #fff;
  padding-top: 70px;
  position: relative;
  z-index: 2;
}

.module-detail .banner .wrapper .content .module-title {
  font-size: 48px;
  font-weight: 600;
  color: inherit;
  line-height: 1.25;
  margin-bottom: 15px;
  letter-spacing: -0.03rem;
}

.module-detail .banner .wrapper .content .description {
  opacity: 0.8;
  font-size: 16px;
  letter-spacing: -0.24px;
  line-height: 27px;
}

.module-detail .lessons {
  padding-top: 35px;
  padding-bottom: 35px;
}

.module-detail .lessons .extra {
  padding-bottom: 35px;
}

.module-detail .lessons .title {
  color: #122b46;
  font-size: 16px !important;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.module-detail .lessons .toggle {
  font-size: 0.875em;
}

.module-detail .lessons .lesson {
  margin-bottom: 10px;
  margin-top: 16px;
  padding: 16px 15px;
  background: rgba(146, 164, 183, 0.05);
  border-radius: 4px;
  color: #939597;
  cursor: pointer;
}

.module-detail .lessons .lesson .name {
  font-weight: 500;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.module-detail .lessons .lesson .subjects-count {
  font-size: 12px;
  font-weight: 400;
}

.module-detail .bar-wrapper {
  width: 400px;
  position: absolute;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  z-index: 2;
  top: calc(83px + 62px);
  right: 60px;
}

.module-detail .hover-bar {
  margin-top: 85px;
  max-width: 360px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 42%;
  flex: 0 0 42%;
  margin-left: auto;
  z-index: 2;
  background-color: white;
  box-shadow: 0 32px 54px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding-bottom: 35px;
}

.module-detail .hover-bar .image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.module-detail .hover-bar .bar-content {
  padding: 20px 30px 0;
}

.module-detail .hover-bar .bar-content .items-title {
  color: #122b46;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 0;
  padding-bottom: 15px;
  text-transform: uppercase;
  padding-top: 20px;
}

.module-detail .hover-bar .bar-content .item {
  font-size: 0.875rem;
  line-height: 1.35;
  margin-bottom: 15px;
  display: flex;
}

.module-detail .hover-bar .bar-content .item svg {
  font-size: 18px;
  margin-right: 8px;
}

.module-detail .mobile {
  display: none;
}

.module-detail .box {
  box-shadow: 0 32px 54px 0 rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
  .module-detail .bar-wrapper {
    display: none;
  }

  .module-detail .wrapper .left {
    max-width: initial;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .module-detail .banner {
    padding-bottom: 20px;
    height: auto;
  }

  .module-detail .mobile {
    display: block;
  }

  .module-detail .mobile .image {
    max-width: 100%;
  }

  .module-detail .mobile.box .items-title {
    margin-top: 15px;
  }
}
