.business-information-container {
  margin: 30px 40px;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  padding: 32px;
  height: auto;
}

.business-information-container .business-title {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 30px;
}

.business-information-container .business-info-items {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.business-information-container .business-info-items label {
  font-weight: 500;
  font-size: 15px;
}

.business-information-container .business-info-items .business-info-item {
  width: 30%;
  position: relative;
}

.business-information-container .business-info-items .business-info-item img {
  height: 117px;
  object-fit: contain;
  margin-top: 10px;
  object-position: left;
}

.business-information-container .business-info-items .business-info-item .add {
  color: var(--pink2);
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  top: 44px;
  right: 20px;
  cursor: pointer;
}

.content.business-content {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: auto;
}

.business-sidebar {
  min-height: calc(100vh - 145px);
}

.business-sidebar .sidebar {
  margin: 0;
}

.business-content {
  background-color: white;
  padding: 30px;
  height: 100%;
}

.business-content .logo-box {
  background-color: #1c4052;
  max-width: 200px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 30px;
}

.business-content .logo-box img {
  background: white;
  border-radius: 100%;
  height: 170px;
  width: 170px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.business-content .logo-box .title {
  color: white;
}

@media (max-width: 1100px) {
  .business-content.content {
    width: calc(100vw - 60px);
  }

  .business-information-container {
    margin: 25px 0px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 10px;
    height: auto;
  }

  .business-information-container .business-info-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .business-information-container .business-info-items .business-info-item {
    width: 90%;
  }
}
