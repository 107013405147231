.scan-results {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
}

.scan-results.two-by-two {
  max-width: calc(700px + 2rem);
}

.result-card .divider {
  width: 100%;
  height: 2px;
  background-color: #6b9aad;
  margin-top: 10px;
  margin-bottom: 10px;
}

.result-card .result-score {
  position: relative;
  width: 100%;
  height: 129px;
}

.result-card .result-bar-overflow {
  position: relative;
  overflow: hidden;
  width: 160px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.result-card .result-bar-overflow .result-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 10px solid #d6dce5;
  transform: rotate(45deg);
}

.result-card .result-bar-overflow .result-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 35px;
}

.result-card .result-bar-overflow .text-score {
  position: absolute;
  bottom: -5px;
  font-size: 15px;
  left: 50%;
  transform: translate(-50%);
}

.result-card .gradient-wrapper {
  position: relative;
}

.result-card .gradient-wrapper .gradient-indicator {
  position: absolute;
  color: #6b9aad;
  top: -18px;
}

.result-card .result-title {
  color: #6b9aad;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
}

.scan-results .results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-flow: dense;
  gap: 2rem;
  margin-top: 50px;
}

.scan-results .results-grid.two-by-two {
  grid-template-columns: repeat(2, minmax(350px, 1fr));
}

@media screen and (max-width: 900px) {
  .scan-results .results-grid.two-by-two {
    grid-template-columns: repeat(1, minmax(350px, 1fr));
  }
}

.scan-results .title {
  margin-top: 15px;
  font-size: 22px;
}

.result-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 320px;
  min-height: 144px;
  border-radius: 25px;
  position: relative;
  font-weight: 500;
  background-color: #ecf1f7;
  padding: 15px;
}
.result-card.open {
  grid-row: span 4;
}

.result-card .result-bar-overflow .result-bar.red {
  border-bottom-color: var(--pink);
  border-right-color: var(--pink);
}

.result-card .result-bar-overflow .result-bar.yellow {
  border-bottom-color: var(--yellow);
  border-right-color: var(--yellow);
}

.result-card .result-bar-overflow .result-bar.green {
  border-bottom-color: var(--green);
  border-right-color: var(--green);
}

.result-card .result-bar-overflow .result-bar.grey {
  border-bottom-color: var(--dark-grey);
  border-right-color: var(--dark-grey);
}

.result-card:hover {
  cursor: pointer;
}

.result-card-text {
  display: flex;
  gap: 20px;
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin-left: 30px;
  margin-right: 35px;
}

.result-card-text-left {
  width: 50%;
  margin-left: 35px;
}

.result-card-text-right {
  width: 50%;
  margin-right: 35px;
}

.result-card-row {
  display: flex;
}

.result-card-value-wrapper {
  position: relative;
}

.result-card-value {
  font-size: 60px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.result-card-range {
  position: absolute;
  bottom: 0;
  right: -30px;
  transform: translate(50%, 0);
}

.result-card-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 10px;
  top: 10px;
  filter: invert(1);
}

.result-card-caret {
  position: absolute;
  right: 5%;
  bottom: 2%;
  color: white;
}

.result-card-title {
  text-align: center;
  margin-left: 45px;
  margin-right: 45px;
  line-height: 15px;
}

.scan-results-title {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .result-card-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .scan-results {
    padding: 30px 0;
  }

  .result-card {
    max-width: calc(100% - 60px - 1rem);
    width: 300px;
    margin: 1rem;
  }

  .result-card-text {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 14px;
  }
  .result-card-text-left {
    width: 100%;
    margin-left: 0px;
  }

  .result-card-text-right {
    width: 100%;
    margin-right: 0px;
  }
}
