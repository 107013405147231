.results {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.results .row {
  height: 120px;
  width: 100%;
}

.results .row.vhigh {
  background-color: #fca281;
}

.results .row.high {
  background-color: #fdb79d;
}

.results .row.rec {
  background-color: #ffc8b3;
}

.results .row.control {
  background-color: #fee8e0;
}

.results .title {
  width: 20%;
  text-align: right;
  margin-right: 10px;
}

.results .wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.results .bar {
  background-color: #72a1af;
  height: 210px;
  width: 35px;
  position: absolute;
  bottom: 0;
}

.results .bar .title {
  margin-top: 210px;
}

.test-option {
  background-color: #ffa176;
  color: white;
  padding: 10px;
  border-radius: 100px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.test-option.selected {
  background-color: #bf7256;
}

@media screen and (max-width: 500px) {
  .aanbevolen-tools {
    width: 90%;
  }

  .aanbevolen-normal {
    width: 95%;
  }

  .wrapper .title {
    display: none;
  }
}
