.about-item {
  display: flex;
  padding-top: 30px;
}

.about-item .top {
  width: 159px;
  height: 159px;
  margin-right: 20px;
}

@media screen and (max-width: 500px) {
  .about-item {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .about-item h5 {
    margin-top: 15px;
  }

  .about-list {
    align-items: center;
  }

  .up {
    margin-top: -40px;
  }

  .about-item .top {
    margin: 0;
  }
}
