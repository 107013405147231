.landingpage {
  background-color: var(--white);
}

.landingpage .block {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.landingpage .content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
}

.landingpage .programs {
  background-color: var(--light-blue);
}

.landingpage .title {
  color: var(--blue);
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.landingpage .subTitle {
  margin-top: 0.5rem;
  color: var(--blue);
  font-size: 24px;
  text-align: center;
}

.landingpage .hero-wrapper {
  background-color: var(--white);
  width: 100%;
  padding-bottom: 30px;
}

.landingpage .hero {
  display: flex;
  padding-top: 30px;
  max-width: 1000px;
}

.landingpage .hero .left,
.landingpage .hero .right,
.landingpage .information .left,
.landingpage .information .right {
  width: 50%;
  display: flex;
  justify-content: center;
}

.landingpage .hero .left {
  flex-direction: column;
}

.landingpage .hero .title {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 124px;
  text-align: unset;
}

.landingpage .hero .subtitle {
  font-size: 16px;
  margin-top: 20px;
  color: black;
}

.landingpage .hero .button {
  color: var(--blue);
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  font-family: Montserrat, sans-serif;
}

.landingpage .information-wrapper {
  box-shadow: 0 2px 8px rgba(14, 19, 24, 0.07);
}

.landingpage .information {
  display: flex;
  width: calc(100% - 40px);
}

.landingpage .information .right {
  flex-direction: column;
  margin-left: 50px;
}

.landingpage .information .right .title {
  text-align: unset;
  margin-bottom: 30px;
}

.landingpage .information .right .text {
  font-size: 18px;
}

.landingpage .waves {
  width: 100%;
  position: relative;
}

.landingpage .waves .content {
  z-index: 1;
}

.landingpage .waves .wave-img {
  position: absolute;
  top: 25px;
  height: 384px;
  width: 100%;
}

.landingpage .stats-wrapper {
  display: flex;
  color: var(--regular-blue);
  gap: 70px;
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
  justify-content: center;
}

.landingpage .stats-wrapper .number {
  font-size: 35px;
  font-weight: bold;
}

.landingpage .stats-wrapper .text {
  font-size: 18px;
  margin-top: 20px;
}

.landingpage .video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landingpage .video .video-iframe {
  width: 1125px;
  height: 500px;
}

.landingpage .video .subtitle {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 35px;
}

.landingpage .mental {
  background-color: var(--light-blue);
  width: 100%;
  position: relative;
  padding-top: 60px;
}

.landingpage .mental .usps {
  display: flex;
  gap: 60px;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.landingpage .mental .usps .usp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  width: 177px;
  text-align: center;
}

.landingpage .mental .usps .usp img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.landingpage .mental .button {
  color: var(--blue);
  font-size: 16px;
  font-weight: bold;

  margin-top: 70px;
  font-family: Montserrat, sans-serif;
  line-height: 27px;
}

.outlined {
  background: none;
  opacity: 70%;
}

.landingpage .buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.landingpage .mental .button a {
  color: var(--blue);
}

.landingpage .waves-block {
  width: 100%;
  margin-top: -160px;
  padding: 0;
  margin-bottom: -33px;
}

.landingpage .history-wrapper {
  background-color: var(--white);
  width: 100%;
  padding: 0;
  margin-top: -20px;
  margin-bottom: -20px;
  min-height: 542px;
}

.landingpage .history {
  display: flex;
}

.landingpage .history .left,
.landingpage .history .right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  object-fit: contain;
}

.landingpage .history .right .right-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  object-fit: contain;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 848px;
}

.landingpage .history .left .title {
  text-align: unset;
}

.landingpage .history .left .text {
  font-size: 18px;
  margin-top: 30px;
}

.landingpage .history .left .text.no-margin {
  margin-top: 0;
}

.landingpage .dashboard-wrapper {
  background-color: var(--light-blue);
  width: 100%;
}

.landingpage .dashboard-wrapper .dashboard {
  display: flex;
  margin-top: 45px;
  gap: 40px;
  width: calc(100% - 40px);
}

.landingpage .dashboard-wrapper .dashboard .left,
.landingpage .dashboard-wrapper .dashboard .right {
  width: 50%;
}

.landingpage .dashboard-wrapper .dashboard .left {
  object-fit: contain;
}

.landingpage .dashboard-wrapper .dashboard .right .subtitle {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}

.landingpage .dashboard-wrapper .dashboard .right .rois {
  display: flex;
  flex-direction: column;
}

.landingpage .dashboard-wrapper .dashboard .right .rois .roi {
  display: flex;
  gap: 25px;
}

.landingpage .dashboard-wrapper .dashboard .right .rois .roi img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.landingpage .dashboard-wrapper .dashboard .right .rois .roi {
  font-size: 18px;
}

.landingpage .testimonials {
  background-color: var(--regular-blue);
  width: 100%;
  padding-bottom: 0;
}

.landingpage .impact-wrapper,
.landingpage .impact {
  background-color: var(--light-blue);
  width: 100%;
}

.landingpage .impact {
  width: calc(100% - 40px);
}

.landingpage .form {
  background-color: var(--light-blue);
  border-radius: 8px;
  padding: 30px;
  margin-top: 80px;
}

.landingpage .form .subtitle {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.landingpage .form .input-row {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 40px;
}

.landingpage .form .input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.landingpage .form .input-wrapper input[type="email"] {
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .landingpage .video .video-iframe {
    width: 830px;
    height: 500px;
  }

  .block .waves-block.upside-down {
    margin-bottom: -70px;
  }
  .block .waves-block.upside {
    margin-top: -70px;
  }
}

@media screen and (max-width: 925px) {
  .landingpage .history-wrapper {
    padding-top: 20px;
  }
}

@media screen and (max-width: 850px) {
  .landingpage .hero {
    flex-direction: column;
    width: calc(100% - 40px);
    text-align: center;
    padding-top: 10px;
  }

  .landingpage .hero .title {
    font-size: 40px;
    margin-bottom: 40px;
  }

  .landingpage .hero .left,
  .landingpage .hero .right,
  .landingpage .information .left,
  .landingpage .information .right,
  .landingpage .history .left,
  .landingpage .history .right,
  .landingpage .dashboard-wrapper .dashboard .left,
  .landingpage .dashboard-wrapper .dashboard .right {
    width: calc(100%);
    justify-content: center;
    align-items: center;
  }

  .landingpage .hero .right {
    width: calc(100%);
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .landingpage .dashboard-wrapper .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landingpage .dashboard-wrapper .dashboard {
    flex-direction: column;
    width: calc(100% - 40px);
    margin-top: 0;
  }

  .landingpage .information,
  .landingpage .history {
    flex-direction: column;
    width: calc(100% - 40px);
  }

  .landingpage .history {
    margin-top: 30px;
  }

  .landingpage .waves-block {
    margin-bottom: 0;
    margin-top: 0;
    height: 87px;
  }

  .landingpage .information .right {
    margin-left: 0;
    margin-top: 30px;
  }

  .landingpage .stats-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .landingpage .stats-wrapper .text {
    width: 120px;
    text-align: center;
  }

  .landingpage .form .input-row {
    flex-direction: column;
  }

  .landingpage .waves .wave-img {
    height: 600px;
  }

  .landingpage .video .video-iframe {
    width: 90vw;
    height: calc(90vw * 3 / 4);
  }

  .landingpage .mental .usps {
    row-gap: 10px;
    column-gap: 10px;
  }

  .landingpage .mental .usps .usp {
    font-size: 16px;
    width: 150px;
  }

  .landingpage .mental .usps .usp img {
    width: 110px;
    height: 110px;
  }

  .landingpage .waves-block {
    height: fit-content;
  }
}

@media screen and (max-width: 800px) {
  .block .waves-block.upside-down {
    margin-bottom: -100px;
  }
  .block .waves-block.upside {
    margin-top: -100px;
  }
}

@media screen and (max-width: 500px) {
  .block .waves-block.upside-down {
    margin-bottom: 0;
  }
  .block .waves-block.upside {
    margin-top: 0;
  }
}
