.toolbox .toolbox-header-container {
  background-color: #72a1af;
  display: flex;
  padding: 65px 0;
  justify-content: center;
}

.toolbox .toolbox-header-container img {
  height: 105px;
}

.toolbox .toolbox-header-container .toolbox-title {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
  color: white;
}

.toolbox .toolbox-box {
  max-width: 300px;
  width: 100%;
  text-align: center;
  margin: 20px;
  background: white;
  padding: 35px;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 13px -11px #000000;
  box-shadow: 0px 0px 13px -11px #000000;
  text-decoration: none;
  color: black;
}

.toolbox .toolbox-box img {
  width: 100px;
  height: 100px;
}

.toolbox .toolbox-box .name {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
}
