.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  padding: 40px;
  color: var(--white);
  width: calc(100% - 80px);
  position: relative;
}

.footer .socials {
  gap: 20px;
  justify-content: start;
  margin-right: 70px;
}

.footer .footer-logo {
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  max-height: 200px;
}

.footer .socials .social {
  background-color: var(--pink);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue);
  width: 50px;
  height: 50px;
  font-size: 30px;
}

.footer .column {
  border-left: 1px solid var(--white);
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-right: 40px;
}

.footer .column .title {
  font-size: 20px;
  font-weight: bold;
}

.footer .column a {
  text-decoration: none;
  color: var(--white);
}

@media screen and (max-width: 980px) {
  .footer .footer-logo {
    max-height: 150px;
  }
}

@media screen and (max-width: 850px) {
  .footer .flex-wrapper {
    flex-direction: column;
    width: 100%;
  }
  .footer .column {
    border-left: none;
  }

  .footer .socials {
    margin-bottom: 30px;
  }

  .footer .column .title {
    margin-top: 25px;
  }

  .footer .column {
    gap: 15px;
  }
}
