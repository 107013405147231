.blog-container {
  margin: 0 auto;
  max-width: 800px;
}

.blog-container .entry-header {
  margin-bottom: 2.1875rem;
}

.blog-container figure {
  margin: 0;
}

.blog-container .entry-img img {
  /* bottom: 0; */
  /* left: 0; */
  /* margin: auto; */
  /* min-height: 100%; */
  /* min-width: 100%; */
  /* object-fit: contain; */
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 0;
}

.blog-container .single .entry-img {
  padding-top: 36%;
  margin: 0 0 32px;
}

.blog-container .entry-img,
.single .post-related-posts .entry-img {
  display: block;
  overflow: hidden;
  padding-top: 55.56%;
  position: relative;
}

.blog-container img {
  border: 0;
  font-style: italic;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.blog-container .wp-block-image .alignright {
  float: right;
  margin: 0.5em 0 0.5em 1em;
}
