.tests-table {
  width: calc(100% - 40px);
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.tests-table thead {
  background: #6b98aa;
  color: white;
}

.tests-table thead th,
.tests-table tbody td {
  padding: 16px;
}

.tests-table thead th:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.tests-table thead th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.tests-table tbody tr:nth-child(even) {
  background-color: #f1f4fa;
}

.tests-table tbody td .flags-container {
  background-color: rgba(107, 152, 170, 0.2);
  border-radius: 8px;
  padding: 7px 20px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.tests-table.pdf {
  max-width: 450px;
}
