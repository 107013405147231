.programs-page h2 {
  font-weight: 700;
  font-size: 23px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-color: var(--yellow);
  border-bottom-width: 2px;
}

.programs-page .box-grid {
  flex-wrap: wrap;
  gap: 30px;
}

.programs-page .box-wrapper {
  background-color: var(--light-blue);
  border-radius: 15px;
  padding: 15px 25px;
  min-width: 250px;
  max-width: 350px;
}

.programs-page .box-wrapper .title {
  font-size: 19px;
}

.programs-page .box-wrapper .sub-title {
  font-size: 17px;
  margin-bottom: 18px;
  font-weight: 600;
}

.programs-page .box-wrapper .module-bar-wrapper {
  background-color: #e7e9ec;
  border-radius: 10px;
  height: 15px;
  margin-bottom: 26px;
}

.programs-page .box-wrapper .module-bar {
  height: 15px;
  background-color: var(--yellow);
  border-radius: 10px;
}

.programs-page .box-wrapper .button {
  padding: 4px 10px;
  text-align: center;
  background-color: var(--orange);
  border-radius: 20px;
  border-color: var(--orange);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  margin-bottom: 10px;
}

.programs-page .box-wrapper .button .arrow {
  display: inline-block;
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-top: 2px solid var(--white) !important;
  border-left: 2px solid var(--white) !important;
  margin: 0px 10px;
  transform: rotate(135deg);
}

@media (max-width: 500px) {
  .programs-page .box-wrapper {
    flex: 1;
  }
}
