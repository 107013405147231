html,
body,
#root {
  min-height: 100%;
  background-color: #fafbfd;
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: #4d5c6d;
}

.fit-object {
  width: 100%;
  object-fit: contain;
}

.red {
  color: red;
}

.entry-content-wrap {
  padding: 0 15px;
}

input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"],
input[type="file"],
textarea,
select {
  width: 100%;
  padding: 12px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

select.text-select {
  width: 100%;
  padding: 0 20px 0 0;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 101%;
  background-position-y: -5px;
}

form {
  color: #122b46;
}

.auth {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 30px;
  color: #122b46;
}

.auth h2 {
  text-align: left;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth .auth-form,
.account .auth-form {
  display: flex;
  flex-direction: column;
  width: 460px;
  max-width: 80%;
  margin-bottom: 50px;
}

.auth.login .auth-form {
  width: 320px;
}

.auth .auth-form .input,
.account .auth-form .input,
form .input {
  margin-top: 10px;
  margin-bottom: 17px;
}

.button {
  font-size: 15px;
  font-weight: 400;
  fill: #6b9aad;
  background-color: #6b9aad;
  color: white;
  border-style: solid;
  border-width: 3px;
  border-color: #6b9aad;
  border-radius: 100px;
  padding: 8px 20px;
  cursor: pointer;
  text-decoration: none;
}

.button.back {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.03);
  color: #939597;
  border-radius: 20px;
  padding: 1px 15px 1px 10px;
  font-size: 12px;
  margin: 0 0 15px;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  border: none;
  width: fit-content;
}

.button.fit {
  width: fit-content;
}

.button:disabled {
  background-color: rgba(0, 0, 0, 0.2);
  fill: #385259;
  color: #385259;
  border-color: #385259;
  cursor: not-allowed;
}

.button:hover:disabled {
  background-color: rgba(0, 0, 0, 0.2);
  fill: #385259;
  color: #385259;
  border-color: #385259;
  cursor: not-allowed;
}

.button:hover {
  background-color: #618a9a;
  border-color: #618a9a;
  color: white;
}

.button.white:hover {
  border-color: #618a9a;
}

.button.white {
  border-color: white;
  fill: white;
  color: white;
}

.button.next-lesson {
  background-color: #d3d7db;
  color: white;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 20px;
}

.button.prev-lesson {
  background-color: #d3d7db;
  color: white;
  border: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 20px;
  margin-right: 3px;
}

.button.red {
  border-color: red;
  fill: red;
  color: red;
}

.button.red:hover {
  border-color: #730000;
  fill: #730000;
  color: white;
  background-color: #730000;
}

.yellow {
  color: #e5aa4f;
}

.button.yellow {
  background-color: #ffbd4a;
  border-color: #ffbd4a;
  color: white;
}

.button.yellow:hover {
  background-color: #e5aa4f;
  border-color: #e5aa4f;
}

.button.yellow.reverse {
  color: #ffbd4a;
  background-color: white;
  border: 2px solid #ffbd4a;
}

.button.yellow.reverse:hover {
  background-color: #ffbd4a;
  border-color: #ffbd4a;
  color: white;
}

.button.pink {
  background-color: #fea180;
  border-color: #fea180;
  color: white;
}

.button.pink:hover {
  background-color: #d8816c;
  border-color: #d8816c;
}

.button.xsmall {
  height: fit-content;
  padding: 5px 10px;
}

.button.small {
  height: fit-content;
  padding: 10px 20px;
}

.container {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 25px;
}

.selected-item {
  background-color: #f1f0f0;
  padding: 10px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: fit-content;
  text-align: center;
}

.overflow-unset {
  overflow: unset !important;
}

.center {
  text-align: center;
}

.auth .auth-form .forgot {
  text-decoration: underline;
  margin-left: auto;
  color: #6b9aad;
  font-size: 12px;
  margin-bottom: 10px;
}

.auth .auth-form .register {
  text-decoration: underline;
  color: #6b9aad;
  font-size: 12px;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.gap {
  gap: 15px;
  margin-bottom: 15px;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.text-center {
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}

.no-decoration {
  text-decoration: none;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.w-fit {
  width: fit-content;
}

.delete {
  cursor: pointer;
  color: #6b9aad;
}

.delete:hover {
  color: #fea180;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}

.table .delete {
  opacity: 0;
}

.table .entry {
  position: relative;
}

.table .entry .delete {
  opacity: 0;
  cursor: pointer;
  color: #9fb3c8;
  position: absolute;
  right: 15px;
}

.table .entry .delete:hover {
  color: red;
}

.table .entry:hover .delete {
  opacity: 1;
}

.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #6b9aad;
  color: white;
}

.table th:first-child {
  border-top-left-radius: 12px;
}

.table th:last-child {
  border-top-right-radius: 12px;
}

tr.expired {
  color: lightgray;
}

.pointer {
  cursor: pointer;
}

.error {
  color: red;
  font-weight: bold;
}

.success {
  color: green;
  font-weight: bold;
}

.outer-table {
  width: 100%;
}

.pagination {
  margin-top: 20px;
}

.pagination .button {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  fill: #218897;
  color: #218897;
  background-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 3px;
  border-color: #218897;
  border-radius: 5px;
  padding: 8px 8px;
  cursor: pointer;
  text-decoration: none;
}

.pagination .button.disabled {
  background-color: #698f98;
  border-color: #698f98;
  color: white;
  cursor: not-allowed;
}

.pagination .page.first {
  border-left: 3px solid #218897;
}

.pagination .page.active {
  margin-left: -3px;
  border-left: 3px solid #124750;
  border-color: #124750;
  color: #124750;
}

.pagination .page {
  border-right: 3px solid #218897;
  border-top: 3px solid #218897;
  border-bottom: 3px solid #218897;
  width: 37.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #218897;
}

.pagination .page:hover {
  margin-left: -3px;
  border-left: 3px solid #124750;
  color: #124750;
  border-color: #124750;
}

.circle-closed {
  background-color: #ffa176;
  border: 1px solid #ffa176;
  border-radius: 100%;
  width: 7px;
  height: 7px;
  margin-right: 5px;
}

.circle-open {
  border: 1px solid #ffa176;
  border-radius: 100%;
  width: 7px;
  height: 7px;
  margin-right: 5px;
}

.decoration-none {
  text-decoration: none;
  color: initial;
}

.text-link {
  text-decoration: underline;
  margin-bottom: 10px;
  cursor: pointer;
}

.title-underlined {
  font-weight: 700;
  font-size: 23px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-color: var(--yellow);
  border-bottom-width: 2px;
}

@media screen and (max-width: 500px) {
  .product {
    width: 100%;
  }

  .outer-table {
    overflow: auto;
  }

  .pagination .page {
    display: none;
  }
}
