.business-documents .documents-title {
  font-weight: 700;
  font-size: 23px;
  margin-bottom: 30px;
}

.business-documents .boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.business-documents .box {
  border-radius: 5px;
  padding: 27px 89px;
  display: flex;
  align-items: center;
  max-width: 230px;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  height: fit-content;
  text-decoration: none;
}

.business-documents .box .box-inside-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.business-documents .box.mailtemplates {
  background: #f8f8ff;
  border: 1px solid #3a36db;
}

.business-documents .box.practices {
  background: #fcfffe;
  border: 1px solid #91d3bc;
}

.business-documents .box.draaiboek {
  background: #fffaf3;
  border: 1px solid #e28b09;
}

.business-documents .box.brochures {
  background: #fff7fa;
  border: 1px solid #ff65b3;
}

.business-documents .box.visuals {
  background: #f9ffff;
  border: 1px solid #03a89e;
}

.business-documents .box.varia {
  background: #fefdff;
  border: 1px solid #cd7ef2;
}

.business-documents .box.report-pink {
  background: #fffbfa;
  border: 1px solid #fea17c;
}

.business-documents .box.report-purple {
  background: #f8f8ff;
  border: 1px solid #707ee0;
}

.business-documents .box.report-blue {
  background: #fafdff;
  border: 1px solid #6b98aa;
}

.business-documents .box.report-yellow {
  background: #fffcf6;
  border: 1px solid #ffba49;
}

.business-documents .box .name {
  font-weight: 700;
  font-size: 20px;
  color: black;
}

.business-documents .box .icon {
  width: 85px;
  height: 85px;
  margin-right: 25px;
}

.business-documents .box .caret {
  margin-left: 10px;
}

.business-documents .box.mailtemplates .caret {
  color: #3a36db;
}

.business-documents .box.practices .caret {
  color: #91d3bc;
}

.business-documents .box.draaiboek .caret {
  color: #e28b09;
}

.business-documents .box.brochures .caret {
  color: #ff65b3;
}

.business-documents .box.visuals .caret {
  color: #03a89e;
}

.business-documents .box.varia .caret {
  color: #cd7ef2;
}

.business-documents .box.report-pink .caret {
  color: #fea17c;
}

.business-documents .box.report-purple .caret {
  color: #707ee0;
}

.business-documents .box.report-blue .caret {
  color: #6b98aa;
}

.business-documents .box.report-yellow .caret {
  color: #ffba49;
}

.business-documents .box .download {
  text-decoration: none;
  color: black;
}

.business-documents .report-year-select {
  margin-left: 277px;
}

.report-year-select {
  font-weight: 500;
  font-size: 20px;
}

@media (max-width: 800px) {
  .business-documents .boxes {
    gap: 15px;
  }

  .business-documents .documents-title {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .business-documents .box {
    padding: 16px 30px;
    max-width: 70vw;
    width: 80%;
  }

  .business-documents .report-year-select {
    margin-left: auto;
    margin-bottom: 15px;
  }
}
