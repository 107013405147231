.sidebar {
  background-color: #fbfbfc;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 200px;
  margin-right: 20px;
  border-right: 1px solid #e7e9ec;
  border-top: 1px solid #e7e9ec;
}

.business-sidebar .sidebar {
  background-color: #fffbfa;
  width: 280px;
  padding: 25px;
}

.sidebar .sidebar-item.active {
  background-color: #dadce0;
  color: #152c48;
}

.sidebar .sidebar-item:hover {
  background-color: #eaebed;
  color: #152c48;
}

.hamburger-menu .sidebar-item,
.sidebar .sidebar-item {
  padding: 15px 20px;
  color: #939597;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar-container {
  height: calc(100% - 76px);
}

.sidebar-container .content {
  width: calc(100% - 201px);
}

.content.business-content {
  width: calc(100% - 340px);
}

.business-sidebar-mobile .hamburger-menu .sidebar-item,
.business-sidebar .sidebar .sidebar-item {
  margin-bottom: 23px;
  color: #565656;
  font-weight: 700;
}

.business-sidebar-mobile .hamburger-menu .menu-title,
.business-sidebar .sidebar .menu-title {
  color: #6b98aa;
  margin-bottom: 23px;
  text-transform: uppercase;
  font-weight: 500;
}

.business-sidebar-mobile .hamburger-menu .sidebar-item .icon,
.business-sidebar .sidebar .sidebar-item .icon {
  margin-right: 7px;
  width: 24px;
  height: 24px;
}

.business-sidebar .sidebar .sidebar-item.active,
.business-sidebar .sidebar .sidebar-item:hover {
  background: var(--pink2);
  color: white;
  border-radius: 3px;
}

.business-sidebar-hamburger {
  filter: brightness(0) saturate(100%) invert(22%) sepia(21%) saturate(1084%)
    hue-rotate(153deg) brightness(95%) contrast(92%);
  width: 40px;
}

.business-sidebar-cross {
  color: var(--blue);
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
}

.hamburger-menu {
  position: absolute;
  left: 0;
  height: calc(100vh - 190px);
  width: 100%;
  flex-direction: column;
  top: 0;
  margin-top: 190px;
  z-index: 1;
  background-color: #fffbfa;
}

.business-sidebar-mobile .hamburger-menu .menu-title {
  display: none;
}

.business-sidebar-mobile .hamburger-menu .sidebar-item {
  margin-bottom: 16px;
  color: #565656;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
}
