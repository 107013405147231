.testview .banner,
.allbanner .banner {
  background-color: #709fad;
  color: white;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testview .test-align {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.testview .banner.justify-evenly,
.allbanner .banner.justify-evenly {
  justify-content: space-evenly;
}

.testview .banner .text,
.allbanner .banner .text {
  text-align: center;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}

.testview .sidebar {
  background-color: #e9eef4;
  width: 300px;
  height: 100vh;
  margin-right: unset;
}

.testview .type {
  margin-left: 20px;
}

.testview .test {
  margin-top: 5px;
  margin-left: 20px;
}

.testview .test-info {
  background-color: #e9eef4;
  padding: 30px;
  margin: 25px 50px 0 50px;
  min-height: 200px;
}

.testview .test-info .test-title {
  color: #fdba55;
  font-size: 20px;
  font-weight: bold;
}

.testview .test-info .test-description {
  color: black;
  margin-top: 10px;
  font-weight: 500;
}

.testview .test-button {
  background-color: #1c4052;
  color: white;
  padding: 6px 8px;
  width: fit-content;
  text-decoration: none;
  margin: 10px auto 25px;
}
